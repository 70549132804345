import { render } from "./SettingRule.vue?vue&type=template&id=4321cd28"
import script from "./SettingRule.vue?vue&type=script&lang=js"
export * from "./SettingRule.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "4321cd28"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('4321cd28', script)) {
    api.reload('4321cd28', script)
  }
  
  module.hot.accept("./SettingRule.vue?vue&type=template&id=4321cd28", () => {
    api.rerender('4321cd28', render)
  })

}

script.__file = "src/components/SettingRule.vue"

export default script