<template>
  <div class="searchBasicDetail-container">
    <a-page-header :title="getPageTitle">
      <template #tags>
        <QuestionCircleOutlined />
      </template>
    </a-page-header>
    <a-form :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-item label="常用搜尋名稱" style="height: 60px">
        <a-input v-model:value="form.title" style="width: 90%" />
        <span style="font-size: 18px">({{ form.title.length }}/48)</span>
        <span
          v-show="form.title.length > 48"
          style="display: block; font-size: 18px; line-height: 18px; color: red"
        >
          不可超過48字元
        </span>
      </a-form-item>

      <!-- <p>常用搜尋條件</p> -->
      <BasicSettingRule
        v-if="route.params.type == 'basic'"
        :typeString="route.params.type"
      />
      <SettingRule
        v-if="route.params.type == 'advanced'"
        :typeString="route.params.type"
      />
      <!-- <a-form-item label="建檔日期">
        <span>{{ form.created_time }}</span>
      </a-form-item>
      <a-form-item label="建檔人">
        <span>{{ form.creator }}</span>
      </a-form-item>
      <a-form-item label="最後編輯日期">
        <span>{{ form.editor }}</span>
      </a-form-item>
      <a-form-item label="最後編輯人">
        <span>{{ form.edit_time }}</span>
      </a-form-item> -->
    </a-form>
    <a-space>
      <a-button style="margin-left: 10px" @click="cancel">取消</a-button>
      <a-button type="primary" @click="save" :disabled="!checkSave">
        儲存
      </a-button>
    </a-space>
  </div>
</template>

<script>
  import { onBeforeMount, computed, ref } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  import { QuestionCircleOutlined } from '@ant-design/icons-vue'

  import BasicSettingRule from '@/components/BasicSettingRule'
  import SettingRule from '@/components/SettingRule'
  export default {
    name: 'purchaseServicePlan',
    components: {
      QuestionCircleOutlined,
      BasicSettingRule,
      SettingRule,
    },
    setup() {
      const route = useRoute()
      const router = useRouter()
      const store = useStore()

      const form = computed(() => store.getters['searchSetting/settingDetail'])
      const rules = computed(() => store.getters['searchSetting/rules'])
      // TODO 查詢使用者staffId
      const userInfo = computed(() => store.getters['user/userInfo'])
      const basicRules = computed(() => store.getters['searchSetting/subRules'])

      // 檢查是否可以存檔
      const checkSave = computed(() => {
        return route.params.type === 'basic'
          ? store.getters['searchSetting/checkBasicSave']
          : store.getters['searchSetting/checkSave']
      })
      const setViewerVisible = ref(false)

      const searchResult = ref([])
      const getPageTitle = () => {
        const newOrEdit = route.params.id === '-1' ? '新增' : '編輯'
        const type = route.params.type === 'basic' ? '' : '進階'
        return `${newOrEdit}健康項目${type}標準值`
      }
      onBeforeMount(async () => {
        store.dispatch('searchSetting/initDetail')
        // params.id = -1 表示新增
        if (route.params.id !== '-1') {
          if (route.params.type === 'basic') {
            await store.dispatch('searchSetting/getBasicSettingDetail', {
              id: route.params.id,
              type: route.params.type,
            })
            form.value.staffId = userInfo.value.account
          }
        } else {
          form.value.staffId = userInfo.value.account
        }
      })

      // 儲存button事件
      const save = async () => {
        if (route.params.type === 'basic') {
          const clauses = []
          basicRules.value.forEach((item) => {
            if (item.operatorItem == 'bw') {
              item.component = [item.operantItem1]
              if (
                typeof item.operantItem2 !== 'undefined' &&
                item.operantItem2 !== ''
              )
                item.component.push(item.operantItem2)
              if (
                typeof item.operantItem3 !== 'undefined' &&
                item.operantItem3 !== ''
              )
                item.component.push(item.operantItem3)
            } else {
              item.component = item.operantItem1
            }
            if (item.checked) {
              if (clauses.length == 0) {
                clauses.push({
                  subject: item.subject,
                  predicate: item.operatorItem,
                  component: item.component,
                })
              } else {
                clauses.push({
                  subject: item.subject,
                  predicate: item.operatorItem,
                  component: item.component,
                  sets: item.sets,
                })
              }
            }
          })
          delete form.value['id']
          const status = await store.dispatch(
            'searchSetting/saveBasicSetting',
            {
              id: route.params.id,
              ...form.value,
              clauses,
            }
          )
          if ([200, 201].includes(status)) {
            router.go(-1)
          }
        } else {
          store.dispatch('searchSetting/saveSetting', {
            ...form.value,
            type: route.params.type,
            rules: rules.value,
            id: route.params.id,
          })
        }
        // router.go(-1)
      }

      // 取消事件
      const cancel = () => {
        router.go(-1)
      }

      // 修改檢視者事件
      const setViewer = () => {
        setViewerVisible.value = true
      }

      return {
        route,
        getPageTitle,
        storeList: computed(() => store.getters['store/storeList']),
        labelCol: { span: 4 },
        wrapperCol: { span: 18 },
        dateFormat: 'YYYY/MM/DD',
        form,
        rules,
        save,
        checkSave,
        cancel,
        setViewer,
        setViewerVisible,
        searchResult,
      }
    },
  }
</script>
<style lang="less">
  .ant-form {
    padding: 15px 0px;
    background-color: #fff;
    .ant-row {
      font-size: 20px;
    }
    label {
      font-size: 20px;
    }
  }
  .ant-space {
    float: right;
    margin: 12px;
  }
  .ant-modal {
    .ant-page-header {
      padding: 0px;
    }
  }
</style>
