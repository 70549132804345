<template>
  <div style="padding-left: 12px">
    <!-- 類別 -->
    <a-row>
      <a-col :span="4">
        <label style="color: rgba(0, 0, 0, 0.6); font-size: 20px">
          量測項目:
        </label>
      </a-col>
      <a-col :span="16">
        <a-select
          v-model:value="settingDetail.type"
          @change="handleTypeChange()"
        >
          <a-select-option
            v-for="item in schema"
            :key="item.type"
            :value="item.type"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-col>
    </a-row>

    <a-row
      :gutter="16"
      v-for="(rule, index) in rules"
      :key="index"
      align="middle"
    >
      <a-col :span="2">
        <a-select
          v-if="index > 0"
          v-model:value="rule.sets"
          :disabled="!rule.checked || getFirstCheckedIndex() === index"
        >
          <a-select-option value="and">並且</a-select-option>
          <!-- <a-select-option value="or">或者</a-select-option> -->
        </a-select>
      </a-col>
      <!-- checkBox -->
      <a-col :span="4">
        <a-checkbox v-model:checked="rule.checked">
          {{ rule.name }}
        </a-checkbox>
      </a-col>

      <!-- 運算子 -->
      <a-col :span="4">
        <a-select v-model:value="rule.operatorItem" :disabled="!rule.checked">
          <a-select-option value="eq">等於</a-select-option>
          <a-select-option
            v-if="getValueDetail(rule.subject).valueType == 'number'"
            value="gt"
          >
            大於
          </a-select-option>
          <a-select-option
            v-if="getValueDetail(rule.subject).valueType == 'number'"
            value="ge"
          >
            大於等於
          </a-select-option>
          <a-select-option
            v-if="getValueDetail(rule.subject).valueType == 'number'"
            value="lt"
          >
            小於
          </a-select-option>
          <a-select-option
            v-if="getValueDetail(rule.subject).valueType == 'number'"
            value="le"
          >
            小於等於
          </a-select-option>
          <a-select-option value="neq">不等於</a-select-option>
          <a-select-option
            v-if="getValueDetail(rule.subject).valueType == 'number'"
            value="bw"
          >
            介於
          </a-select-option>
          <a-select-option
            v-if="getValueDetail(rule.subject).valueType == 'number'"
            value="nbw"
          >
            不介於
          </a-select-option>
          <a-select-option
            v-if="getValueDetail(rule.subject).valueType == 'string'"
            value="contains"
          >
            包含
          </a-select-option>
        </a-select>
      </a-col>

      <a-col :span="6">
        <a-select
          v-if="getValueDetail(rule.subject).valueType == 'list'"
          v-model:value="rule.operantItem1"
          style="width: 100%"
          :disabled="!rule.checked"
        >
          <a-select-option
            v-for="item in getValueDetail(rule.subject).list"
            :key="item.value"
            :value="item.value"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
        <a-input
          v-if="
            getValueDetail(rule.subject).valueType == 'string' &&
            rule.operatorItem !== 'bw'
          "
          :disabled="!rule.checked"
          v-model:value="rule.operantItem1"
          style="width: 100%"
        />
        <a-input-number
          v-if="
            getValueDetail(rule.subject).valueType == 'number' &&
            rule.operatorItem !== 'bw'
          "
          :disabled="!rule.checked"
          v-model:value="rule.operantItem1"
          style="width: 100%"
        />
        <a-input-group compact v-if="rule.operatorItem == 'bw'">
          <a-input-number
            v-model:value="rule.operantItem1"
            style="width: 40%; text-align: center"
            :disabled="!rule.checked"
            placeholder="Minimum"
          />
          <a-input
            style="
              width: 10%;
              border-left: 0;
              pointer-events: none;
              backgroundcolor: #fff;
            "
            placeholder="~"
            disabled
          />
          <a-input-number
            v-model:value="rule.operantItem2"
            style="width: 40%; text-align: center; border-left: 0"
            :disabled="!rule.checked"
            placeholder="Maximum"
          />
        </a-input-group>
        <StoreSelector
          :area_id="rule.operantItem1"
          :area_sub_id="rule.operantItem2"
          :store_id="rule.operantItem3"
          :disabled="rule.checked"
          v-if="getValueDetail(rule.subject).valueType == 'store'"
          @click="getParentIndex(index)"
          @get-value="handleGetStoreValue"
        />
      </a-col>
    </a-row>
  </div>
</template>

<script>
  import { computed, onBeforeMount, ref } from 'vue'
  import { useStore } from 'vuex'
  import StoreSelector from '@/components/StoreSelector'
  export default {
    name: 'settingRule',
    components: {
      StoreSelector,
    },
    props: {
      typeString: {
        type: String,
        default: 'basic',
      },
    },
    setup(props) {
      const store = useStore()
      const settingDetail = computed(
        () => store.getters['searchSetting/settingDetail']
      )
      const rules = computed(() => store.getters['searchSetting/subRules'])
      const rulesChangeIndex = ref(0)
      // 篩選項目架構
      const schema = computed(() => store.getters['searchSetting/schema'])
      onBeforeMount(() => {
        store.dispatch('searchSetting/initSchema', props.typeString)
      })

      // 找第一筆啟用項目(供量測項目前面的And/Or，是否啟用使用)
      const getFirstCheckedIndex = () => {
        return rules.value.findIndex((item) => item.checked)
      }

      // 取得項目資料內容
      const getValueDetail = (key) => {
        const schemaItems = schema.value.find(
          (item) => item.type === settingDetail.value.type
        ).items
        return schemaItems.find((item) => item.subject === key)
      }

      // 類別更新事件：如果是第一項被更動，就全部條件清除，只留第一項選取的類別
      const handleTypeChange = () => {
        store.dispatch('searchSetting/setSubRules', 'basic')
      }

      // 取得門市資料
      const handleGetStoreValue = (value) => {
        rules.value[rulesChangeIndex.value].operantItem1 = value.area_id
        rules.value[rulesChangeIndex.value].operantItem2 = value.area_sub_id
        rules.value[rulesChangeIndex.value].operantItem3 = value.store_id
      }

      const getParentIndex = (index) => {
        rulesChangeIndex.value = index
      }

      return {
        rules,
        schema,
        settingDetail,
        getValueDetail,
        handleTypeChange,
        handleGetStoreValue,
        getParentIndex,
        getFirstCheckedIndex,
      }
    },
  }
</script>

<style>
  label {
    margin-right: 100px;
    min-width: 150px;
    display: inline-block;
    text-align: left;
  }
</style>
