import { render } from "./BasicSettingRule.vue?vue&type=template&id=68cfb4e4"
import script from "./BasicSettingRule.vue?vue&type=script&lang=js"
export * from "./BasicSettingRule.vue?vue&type=script&lang=js"

import "./BasicSettingRule.vue?vue&type=style&index=0&id=68cfb4e4&lang=css"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "68cfb4e4"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('68cfb4e4', script)) {
    api.reload('68cfb4e4', script)
  }
  
  module.hot.accept("./BasicSettingRule.vue?vue&type=template&id=68cfb4e4", () => {
    api.rerender('68cfb4e4', render)
  })

}

script.__file = "src/components/BasicSettingRule.vue"

export default script