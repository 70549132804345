import { render } from "./_id.vue?vue&type=template&id=71925fd1"
import script from "./_id.vue?vue&type=script&lang=js"
export * from "./_id.vue?vue&type=script&lang=js"

import "./_id.vue?vue&type=style&index=0&id=71925fd1&lang=less"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "71925fd1"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('71925fd1', script)) {
    api.reload('71925fd1', script)
  }
  
  module.hot.accept("./_id.vue?vue&type=template&id=71925fd1", () => {
    api.rerender('71925fd1', render)
  })

}

script.__file = "src/views/setting/_id.vue"

export default script