import { render } from "./StoreSelector.vue?vue&type=template&id=6a57f3e0"
import script from "./StoreSelector.vue?vue&type=script&lang=js"
export * from "./StoreSelector.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "6a57f3e0"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('6a57f3e0', script)) {
    api.reload('6a57f3e0', script)
  }
  
  module.hot.accept("./StoreSelector.vue?vue&type=template&id=6a57f3e0", () => {
    api.rerender('6a57f3e0', render)
  })

}

script.__file = "src/components/StoreSelector.vue"

export default script